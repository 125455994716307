<template>
  <v-card class="accounting-document d-flex flex-column" style="height: 100%;">
    <v-toolbar color="grey darken-3" dark elevation="1" style="flex: 0" height="52">
      <v-toolbar-title>
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-card-text class="pt-3 pb-1" style="flex: 1; display: flex; flex-direction: column; overflow: hidden;">
      <div class="conducted-box d-flex">
        <v-alert
            dense
            text
            class="mb-0 py-1"
            :color="conducted ? 'success' : 'secondary'"
            :type="conducted ? 'success' : 'warning'"
            style="flex: 1"
        >
          {{ conducted ? 'Документ проведений' : 'Документ не проведений' }}
        </v-alert>
      </div>
      <div class="document-header" style="flex: 0">
        <div class="document-header-row pt-2 pb-1">
          <div style="flex: 0 0 180px; margin-right: 16px">
            <date_input v-model="date" label="Дата: " :time="true" @userChange="onDateChange"></date_input>
          </div>
          <div style="flex: 0 0 140px; margin-right: 16px">
            <string_input v-model="number" label="Номер: " :disabled="true"></string_input>
          </div>
          <div style="flex: 0 0 100px; margin-right: 16px">
            <select_input
                v-model="currency_id"
                :computed-action="'SELECT_CURRENCY_LIST_ELEMENTS'"
                :computed-getter="'get_currency_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                label="Валюта: "
                :select-button-object="{
                                name: 'CURRENCY_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список валют',
                                element_title: 'Валюта',
                                show: true
                              }"
                @selectChange="onDateChange"
            />
          </div>
          <div style="flex: 1;">
            <string_input v-model="comment" :required="false" label="Коментар: "></string_input>
          </div>
        </div>
      </div>
      <div class="document-body pt-0 pb-1" style="flex: 1; display: flex; flex-direction: column; overflow: hidden;">
        <div class="px-4 pt-2 pb-0" style="flex: 1; display: flex; height: 100%;">
          <v-row style="height: 100%;">
            <v-col cols="12" sm="12" md="12" class="grey lighten-3 pb-0" style="overflow: hidden; height: 100%;">
              <v-tabs class="custom-tabs without" color="success" style="height: 100%; ">
                <v-tab>
                  Операції
                </v-tab>

                <v-tab-item class="pa-0" style="height: 100%;">
                  <div class="command-panel mt-2">
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="success mr-2" :disabled="!document_state.header_ready"
                           @click.stop="addTableRow"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Додати рядок
                    </v-btn>
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="error lighten-1" :disabled="!document_state.header_ready"
                           @click.stop="clearDialog"
                    >
                      <v-icon small left>mdi-close</v-icon>
                      Очистити таблицю
                    </v-btn>
                  </div>
                  <div class="document-table with-command mt-2">
                    <div class="document-table-body">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col" style="flex: 0 0 70px">
                            № п/п
                          </div>
                          <div class="document-table-col" style="flex: 0 0 250px">
                            Рахунок Дт
                          </div>
                          <div class="document-table-col-group" style="flex: 0 0 250px">
                            <div class="document-table-col">
                              Субконто 1 (Дт)
                            </div>
                            <div class="document-table-col">
                              Субконто 2 (Дт)
                            </div>
                            <div class="document-table-col">
                              Субконто 3 (Дт)
                            </div>
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px">
                            К-сть Дт
                          </div>
                          <div class="document-table-col" style="flex: 0 0 250px">
                            Рахунок Кт
                          </div>
                          <div class="document-table-col-group" style="flex: 0 0 250px">
                            <div class="document-table-col">
                              Субконто 1 (Кт)
                            </div>
                            <div class="document-table-col">
                              Субконто 2 (Кт)
                            </div>
                            <div class="document-table-col">
                              Субконто 3 (Кт)
                            </div>
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px">
                            К-сть Кт
                          </div>
                          <div class="document-table-col" style="flex: 0 0 160px">
                            Сума операції
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge) in table"
                           :key="`charge-${charge.row_num}`"
                      >
                        <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                          <div style="flex: 1; padding-left: 4px">
                            {{ table.indexOf(charge) + 1 }}
                          </div>
                          <div style="flex:  0 0 32px">
                            <v-btn icon small class="grey lighten-3" @click="deleteTableRow(charge)">
                              <v-icon small color="grey darken-2">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 250px">
                          <select_input
                              v-model="charge.debit_chart_of_account_id"
                              :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                              :computed-getter="'get_chart_of_accounts_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              small
                              transparent
                              :select-button-object="{
                            name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                            group: '',
                            hierarchy: false,
                            only_groups: false,
                            title: 'План рахунків',
                            element_title: 'Рахунок',
                            show: true,
                          }"
                              :required="true"
                              @selectChange="afterDebitChartAccountChange"
                          />
                        </div>
                        <div class="document-table-col-group" style="flex: 0 0 250px">
                          <div class="document-table-col">
                            <template v-if="charge.debit_account_detail_1_type">
                              <select_input
                                  v-model="charge.debit_account_detail_1_value"
                                  :computed-action="subconto_type_select_setting[charge.debit_account_detail_1_type].computed_action"
                                  :computed-getter="subconto_type_select_setting[charge.debit_account_detail_1_type].computed_getter"
                                  :input-value-as-value="false"
                                  :item-search="'text'"
                                  :show-on-focus="false"
                                  :row_num="charge.row_num"
                                  :show-select-on-focus="false"
                                  small
                                  transparent
                                  :required="true"
                                  :select-button-object="{
                                name: subconto_type_select_setting[charge.debit_account_detail_1_type].select_button_object.name,
                                group: subconto_type_select_setting[charge.debit_account_detail_1_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[charge.debit_account_detail_1_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[charge.debit_account_detail_1_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[charge.debit_account_detail_1_type].select_button_object.title,
                                element_title: subconto_type_select_setting[charge.debit_account_detail_1_type].select_button_object.element_title,
                                show: true
                              }"/>
                            </template>
                          </div>
                          <div class="document-table-col">
                            <template v-if="charge.debit_account_detail_2_type">
                              <select_input
                                  v-if="charge.debit_account_detail_2_type"
                                  v-model="charge.debit_account_detail_2_value"
                                  :computed-action="subconto_type_select_setting[charge.debit_account_detail_2_type].computed_action"
                                  :computed-getter="subconto_type_select_setting[charge.debit_account_detail_2_type].computed_getter"
                                  :input-value-as-value="false"
                                  :item-search="'text'"
                                  :show-on-focus="false"
                                  :row_num="charge.row_num"
                                  :action-props="{
                                    owner_id: charge.debit_account_detail_2_type === 'contract' ? charge.debit_account_detail_1_value || -1 : null}"
                                  :show-select-on-focus="false"
                                  small
                                  transparent
                                  :required="true"
                                  :sync-action-props="charge.debit_account_detail_2_type === 'contract'"
                                  :select-button-object="{
                                name: subconto_type_select_setting[charge.debit_account_detail_2_type].select_button_object.name,
                                group: subconto_type_select_setting[charge.debit_account_detail_2_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[charge.debit_account_detail_2_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[charge.debit_account_detail_2_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[charge.debit_account_detail_2_type].select_button_object.title,
                                element_title: subconto_type_select_setting[charge.debit_account_detail_2_type].select_button_object.element_title,
                                action_props: {owner_id: charge.debit_account_detail_2_type === 'contract' ? charge.debit_account_detail_1_value || -1 : null},
                                show: true,
                                sync_action_props: charge.debit_account_detail_2_type === 'contract'
                              }"/>
                            </template>
                          </div>
                          <div class="document-table-col">
                            <template v-if="charge.debit_account_detail_3_type">
                              <select_input
                                  v-if="charge.debit_account_detail_3_type"
                                  v-model="charge.debit_account_detail_3_value"
                                  :computed-action="subconto_type_select_setting[charge.debit_account_detail_3_type].computed_action"
                                  :computed-getter="subconto_type_select_setting[charge.debit_account_detail_3_type].computed_getter"
                                  :input-value-as-value="false"
                                  :item-search="'text'"
                                  :show-on-focus="false"
                                  :row_num="charge.row_num"
                                  :show-select-on-focus="false"
                                  small
                                  transparent
                                  :required="true"
                                  :select-button-object="{
                                name: subconto_type_select_setting[charge.debit_account_detail_3_type].select_button_object.name,
                                group: subconto_type_select_setting[charge.debit_account_detail_3_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[charge.debit_account_detail_3_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[charge.debit_account_detail_3_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[charge.debit_account_detail_3_type].select_button_object.title,
                                element_title: subconto_type_select_setting[charge.debit_account_detail_3_type].select_button_object.element_title,
                                show: true
                              }"/>
                            </template>
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 100px">
                          <number_input :row_num="charge.row_num" v-model="charge.debit_count" v-decimal transparent
                                        small
                                        :required="false"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 250px">
                          <select_input
                              v-model="charge.credit_chart_of_account_id"
                              :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                              :computed-getter="'get_chart_of_accounts_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              small
                              transparent
                              :select-button-object="{
                            name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                            group: '',
                            hierarchy: false,
                            only_groups: false,
                            title: 'План рахунків',
                            element_title: 'Рахунок',
                            show: true,
                          }"
                              :required="true"
                              @selectChange="afterCreditChartAccountChange"
                          />
                        </div>
                        <div class="document-table-col-group" style="flex: 0 0 250px">
                          <div class="document-table-col">
                            <template v-if="charge.credit_account_detail_1_type">
                              <select_input
                                  v-model="charge.credit_account_detail_1_value"
                                  :computed-action="subconto_type_select_setting[charge.credit_account_detail_1_type].computed_action"
                                  :computed-getter="subconto_type_select_setting[charge.credit_account_detail_1_type].computed_getter"
                                  :input-value-as-value="false"
                                  :item-search="'text'"
                                  :show-on-focus="false"
                                  :row_num="charge.row_num"
                                  :show-select-on-focus="false"
                                  small
                                  transparent
                                  :required="true"
                                  :select-button-object="{
                                name: subconto_type_select_setting[charge.credit_account_detail_1_type].select_button_object.name,
                                group: subconto_type_select_setting[charge.credit_account_detail_1_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[charge.credit_account_detail_1_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[charge.credit_account_detail_1_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[charge.credit_account_detail_1_type].select_button_object.title,
                                element_title: subconto_type_select_setting[charge.credit_account_detail_1_type].select_button_object.element_title,
                                show: true
                              }"/>
                            </template>
                          </div>
                          <div class="document-table-col">
                            <template v-if="charge.credit_account_detail_2_type">
                              <select_input
                                  v-if="charge.credit_account_detail_2_type"
                                  v-model="charge.credit_account_detail_2_value"
                                  :computed-action="subconto_type_select_setting[charge.credit_account_detail_2_type].computed_action"
                                  :computed-getter="subconto_type_select_setting[charge.credit_account_detail_2_type].computed_getter"
                                  :input-value-as-value="false"
                                  :item-search="'text'"
                                  :show-on-focus="false"
                                  :row_num="charge.row_num"
                                  :action-props="{
                                    owner_id: charge.credit_account_detail_2_type === 'contract' ? charge.credit_account_detail_1_value || -1 : null}"
                                  :show-select-on-focus="false"
                                  small
                                  transparent
                                  :required="true"
                                  :sync-action-props="charge.credit_account_detail_2_type === 'contract'"
                                  :select-button-object="{
                                name: subconto_type_select_setting[charge.credit_account_detail_2_type].select_button_object.name,
                                group: subconto_type_select_setting[charge.credit_account_detail_2_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[charge.credit_account_detail_2_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[charge.credit_account_detail_2_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[charge.credit_account_detail_2_type].select_button_object.title,
                                element_title: subconto_type_select_setting[charge.credit_account_detail_2_type].select_button_object.element_title,
                                action_props: {owner_id: charge.credit_account_detail_2_type === 'contract' ? charge.credit_account_detail_1_value || -1 : null},
                                sync_action_props: charge.credit_account_detail_2_type === 'contract',
                                show: true
                              }"/>
                            </template>
                          </div>
                          <div class="document-table-col">
                            <template v-if="charge.credit_account_detail_3_type">
                              <select_input
                                  v-if="charge.credit_account_detail_3_type"
                                  v-model="charge.credit_account_detail_3_value"
                                  :computed-action="subconto_type_select_setting[charge.credit_account_detail_3_type].computed_action"
                                  :computed-getter="subconto_type_select_setting[charge.credit_account_detail_3_type].computed_getter"
                                  :input-value-as-value="false"
                                  :item-search="'text'"
                                  :show-on-focus="false"
                                  :row_num="charge.row_num"
                                  :show-select-on-focus="false"
                                  small
                                  transparent
                                  :required="true"
                                  :select-button-object="{
                                name: subconto_type_select_setting[charge.credit_account_detail_3_type].select_button_object.name,
                                group: subconto_type_select_setting[charge.credit_account_detail_3_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[charge.credit_account_detail_3_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[charge.credit_account_detail_3_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[charge.credit_account_detail_3_type].select_button_object.title,
                                element_title: subconto_type_select_setting[charge.credit_account_detail_3_type].select_button_object.element_title,
                                show: true
                              }"/>
                            </template>
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 100px">
                          <number_input :row_num="charge.row_num" v-model="charge.credit_count" v-decimal transparent
                                        small
                                        :required="false"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 160px">
                          <number_input :row_num="charge.row_num" v-model="charge.result" v-decimal transparent
                                        small
                                        :required="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
    <v-card-actions style="flex: 0">
      <v-spacer></v-spacer>
      <v-btn depressed color="grey lighten-3" v-if="!conducted" :loading="getModalLoading" :disabled="isNew"
             @click="doConducted(true)">
        <v-icon color="success">mdi-file-check-outline</v-icon>
      </v-btn>
      <v-btn depressed color="grey lighten-3" v-if="conducted" :loading="getModalLoading" :disabled="isNew"
             @click="doConducted(false)">
        <v-icon color="error lighten-1">mdi-file-cancel-outline</v-icon>
      </v-btn>
      <v-btn depressed text color="secondary darken-1" :loading="getModalLoading"
             @click="crud_item" class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Провести та закрити
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_DOCUMENT_MANUAL_OPERATION_ELEMENTS,
  UPDATE_DOCUMENT_MANUAL_OPERATION_ELEMENTS,
  REMOVE_DOCUMENT_MANUAL_OPERATION_ELEMENTS,
  CONDUCTED_DOCUMENT_MANUAL_OPERATION_ELEMENTS,
} from "@/store/actions/accounting/list";
import {mapGetters} from 'vuex'
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import enteringBalanceAPI from "@/utils/axios/accounting/documents/manual_operation";
import {maxBy} from "lodash";
import {subconto_type_select_setting} from "@/utils/accounting";
import chartOfAccountAPI from "@/utils/axios/accounting/chart_of_account";

import ModalAccountingComponentMixin from "@/mixins/modal_account_component";
import {format_date_from_python} from "@/utils/icons";

const modalDeleteId = 'manual_operation_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_Document_ManualOperation",
  mixins: [ModalAccountingComponentMixin],
  components: {
    date_input: () => import("@/components/accounting/input/document_date_input"),
    number_input: () => import("@/components/accounting/input/document_number_input"),
    string_input: () => import("@/components/accounting/input/document_string_input"),
    select_input: () => import("@/components/accounting/input/document_select_input")
  },
  data() {
    return {
      subconto_type_select_setting,
      currency_id: this.item.currency_id || null,
      comment: this.item.comment || null,
      table: [],
    }
  },
  methods: {
    checkDocumentTable(filters, table_name, table) {
      const table_charge_error = []
      const items = this[table].filter(item => {
        let r = false
        Object.keys(filters).forEach(key => {
          if (item[key] === undefined || item[key] === null || item[key] === 0) {
            r = true
          }
        })
        return r
      })

      items.forEach(item => {
        const idx = this[table].indexOf(item)
        const row_key = `row_${idx}`

        Object.keys(filters).forEach(key => {
          const filter_row = filters[key]
          let skip = false

          if (filter_row.condition) {
            if (item[filter_row.condition] !== null && item[filter_row.condition] !== undefined && item[filter_row.condition] !== 0) {
              skip = item[key] !== null && item[key] !== undefined && item[key] !== 0
            } else {
              skip = true
            }
          } else {
            skip = item[key] !== null && item[key] !== undefined && item[key] !== 0;
          }

          if (!skip) {
            if (!table_charge_error[row_key]) {
              table_charge_error[row_key] = {row_num: idx + 1, text: filters[key].text}
            } else {
              table_charge_error[row_key].text += `// ${filters[key].text}`
            }
          }
        })
      })

      Object.keys(table_charge_error).forEach(key => {
        this.document_state.errors.push(
            {table: table_name, row_num: table_charge_error[key].row_num, text: table_charge_error[key].text}
        )
      })
    },
    checkDocument(show = false, table = true) {
      this.document_state.show = false
      this.document_state.errors = []

      const header = {
        'date': {id: 'date', text: 'Не заповнена дата документа'},
        'currency_id': {id: 'currency_id', text: 'Не заповнена валюта'},
      }

      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: header[key].text}
          )
        }
      })

      const balances_table = {
        'debit_chart_of_account_id': {
          id: 'debit_chart_of_account_id',
          table: 'Операції',
          text: 'Не заповнений рахунок Дт'
        },
        'credit_chart_of_account_id': {
          id: 'credit_chart_of_account_id',
          table: 'Операції',
          text: 'Не заповнений рахунок Кт'
        },
        'result': {id: 'result', table: 'Операції', text: 'Не заповнена сума операції'},
        'debit_account_detail_1_value': {
          condition: 'debit_account_detail_1_type',
          text: 'Не заповнене субконто 1 (Дт)'
        },
        'debit_account_detail_2_value': {
          condition: 'debit_account_detail_2_type',
          text: 'Не заповнене субконто 2 (Дт)'
        },
        'debit_account_detail_3_value': {
          condition: 'debit_account_detail_3_type',
          text: 'Не заповнене субконто 3 (Дт)'
        },
        'credit_account_detail_1_value': {
          condition: 'credit_account_detail_1_type',
          text: 'Не заповнене субконто 1 (Кт)'
        },
        'credit_account_detail_2_value': {
          condition: 'credit_account_detail_2_type',
          text: 'Не заповнене субконто 2 (Кт)'
        },
        'credit_account_detail_3_value': {
          condition: 'credit_account_detail_3_type',
          text: 'Не заповнене субконто 3 (Кт)'
        },
      }


      this.document_state.header_ready = this.document_state.errors.length === 0;

      if (table) {
        this.checkDocumentTable(balances_table, 'Залишки', 'table')
      }

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = show
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }

    },
    doConducted(payload) {
      if (this.isNew) return

      this.$store.dispatch(CONDUCTED_DOCUMENT_MANUAL_OPERATION_ELEMENTS,
          {document_id: this.itemId, conducted: payload}
      )
          .then(data => {
            if (data) {
              const text = payload ? 'Документ успішно проведений' : 'Відміна проведення успішно виконана'
              const color = payload ? 'success' : 'error'
              this.conducted = payload
              this.$store.commit(ALERT_SHOW, {text, color})
            }

          })
    },
    getChartsOfAccounts() {
      chartOfAccountAPI.get_all()
          .then(response => response.data)
          .then(data => {
            this.charts = data
          })
    },

    clearPersonData(payload) {
      let local_data = payload || {}

      this.itemId = payload.id || null
      this.date = local_data.date || null
      this.conducted = local_data.conducted || false
      this.currency_id = local_data.currency_id || null
      this.comment = local_data.comment || null

      this.table = []
    },
    fetch_data_by_id() {
      this.getChartsOfAccounts()
      if (this.isNew) return

      enteringBalanceAPI.get_document_by_id(this.itemId)
          .then(response => response.data)
          .then(data => {
            this.date = data.date || null
            this.number = data.number || null
            this.conducted = data.conducted || false
            this.currency_id = data.currency_id || null
            this.comment = data.comment || null
            this.table = data.table.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })
          })
    },

    onDateChange() {
      this.$nextTick(() => {
        this.checkDocument(false, false)
      })
    },
    tableColChange() {
      this.$nextTick(() => {
        this.checkDocument(false, true)
      })
    },
    get_account_details(chart_id) {
      const chart = this.charts.find(c => c.id === chart_id) || {}
      const details = {
        account_detail_1: {id: null, type: null},
        account_detail_2: {id: null, type: null},
        account_detail_3: {id: null, type: null},
      }
      if (chart.id) {
        const ch_details = chart.details || []

        const obj_1 = ch_details.find(i => i.order === 1)
        const obj_2 = ch_details.find(i => i.order === 2)
        const obj_3 = ch_details.find(i => i.order === 3)

        if (obj_1) {
          details.account_detail_1.type = obj_1.detail_type
          details.account_detail_1.id = obj_1.id
        }
        if (obj_2) {
          details.account_detail_2.type = obj_2.detail_type
          details.account_detail_2.id = obj_2.id
        }
        if (obj_3) {
          details.account_detail_3.type = obj_3.detail_type
          details.account_detail_3.id = obj_3.id
        }
      }
      return details
    },
    afterDebitChartAccountChange(payload) {
      const row = this.table.find(item => item.row_num === payload.row_num)
      if (row) {
        let details = {}
        if ('text' in payload && 'value' in payload) {
          details = this.get_account_details(payload.value)
        } else {
          details = this.get_account_details(payload.id)
        }
        row.debit_account_detail_1_type = details.account_detail_1.type
        row.debit_account_detail_1_value = null
        row.debit_account_detail_2_type = details.account_detail_2.type
        row.debit_account_detail_2_value = null
        row.debit_account_detail_3_type = details.account_detail_3.type
        row.debit_account_detail_3_value = null
      }
      this.$nextTick(() => {
        this.checkDocument(false, true)
      })
    },
    afterCreditChartAccountChange(payload) {
      const row = this.table.find(item => item.row_num === payload.row_num)
      if (row) {
        let details = {}
        if ('text' in payload && 'value' in payload) {
          details = this.get_account_details(payload.value)
        } else {
          details = this.get_account_details(payload.id)
        }
        row.credit_account_detail_1_type = details.account_detail_1.type
        row.credit_account_detail_1_value = null
        row.credit_account_detail_2_type = details.account_detail_2.type
        row.credit_account_detail_2_value = null
        row.credit_account_detail_3_type = details.account_detail_3.type
        row.credit_account_detail_3_value = null
      }
      this.$nextTick(() => {
        this.checkDocument(false, true)
      })
    },
    deleteTableRow(payload) {
      this.table.splice(this.table.indexOf(payload), 1)
    },
    getNewRowNum(table) {
      const max_row_item = maxBy(this[table], 'row_num')

      let max_row_num = 1
      if (max_row_item) {
        max_row_num = max_row_item.row_num + 1
      }
      return max_row_num
    },
    addTableRow() {
      this.table.push(
          {
            debit_chart_of_account_id: null,
            debit_account_detail_1_value: null,
            debit_account_detail_2_value: null,
            debit_account_detail_3_value: null,
            debit_account_detail_1_type: null,
            debit_account_detail_2_type: null,
            debit_account_detail_3_type: null,
            debit_count: 0,
            credit_chart_of_account_id: null,
            credit_account_detail_1_value: null,
            credit_account_detail_2_value: null,
            credit_account_detail_3_value: null,
            credit_account_detail_1_type: null,
            credit_account_detail_2_type: null,
            credit_account_detail_3_type: null,
            credit_count: 0,
            row_num: this.getNewRowNum('table'),
          }
      )
    },
    clearDialog() {
      const payload = {
        text: `Очистити таблицю із операціями?`,
        accept_button: true,
        id: 'clear_table'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },

    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.date = this.item.date || null
      this.number = this.item.number || null
      this.conducted = this.item.conducted || null
      this.currency_id = this.item.currency_id || null
      this.comment = this.item.comment || null
      this.table = []

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення ручної операції № ${this.number} від ${format_date_from_python(this.date)}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      const payload = {
        date: this.date,
        table: this.table,
        currency_id: this.currency_id,
        comment: this.comment
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_DOCUMENT_MANUAL_OPERATION_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.number = this.number
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_DOCUMENT_MANUAL_OPERATION_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher_item = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.clearPersonData(payload)
              this.fetch_data_by_id()
              this.checkDocument()
            }
          }
      )
    },
    watch_dialog() {
      this.watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher_item) {
                  this.watcher_item()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_DOCUMENT_MANUAL_OPERATION_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
              if (payload.id === 'clear_table') {
                if (payload.answer) {
                  this.table = []
                }
              }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      settings: 'getAccountingGeneralSettings'
    }),
  }
}
</script>
